.githubid {

  background-image: url("./images/githublogo.png");
  background-size: cover; 
  background-position: center;
}

.linkednid {
  background-image: url("./images/linkednlogo.png"); 
  background-size: cover; 
  background-position: center; 
}
.facebookid {
  background-image: url("./images/facebooklogo.png");
  background-size: cover; 
  background-position: center; 
}
.facebookid:hover,
.instagramid:hover,
.githubid:hover,
.linkednid:hover {
  opacity: 0.8;
}
.facebookid,
.instagramid,
.githubid,
.linkednid {
  color: #0366d6;
  text-decoration: none;
  display: inline-block;
  width: 45px;
  height: 45px;
  margin-left: 5px;
  border-radius: 50%;
  margin: 4px;
}
.instagramid {
  background-image: url("./images/instagramlogo.png");
  background-size: cover;
  background-position: center;
}
.githubid .facebookid .linkednid .instagramid :hover {
  opacity: 0.8; 
}
