.App {
  font-family: sans-serif;
  text-align: center;
}

.img-icon {
  width: 200px;
}
.typed {
  display: inline;
}

.profile-card__name {
  font-family: "Quicksand";
}

.profile-card__txt {
  font-family: "Ubuntu";
}

.profile-card-loc__txt {
  font-family: "Quicksand";
}
